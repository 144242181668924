import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

//Import Images
import modern01 from "../../assets/images/modern01.jpg";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      Contactvisible: false,
    };
    this.openModal = this.openModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="bg-half-170 d-table w-100"
          style={{ background: `url(${modern01})center center` }}
          id="home"
        >
          <div className="bg-overlay"></div>
          <Container>
            <Row className="align-items-center mt-md-5">

              <Col
                lg={{ size: 7 }}
                md={{ size: 6, order: 2 }}
                xs={{ order: 1 }}
              >
                <div className="title-heading mt-4 ms-lg-4">
                  <h1 className="heading title-dark text-white mb-3">
                    Powerful AI Solution For Your Business{" "}
                  </h1>
                  <p className="para-desc para-dark text-white-50">
                    HyperDX offers technology-driven managed services
                    to businesses seeking digital transformation
                    by integrating generative AI solutions.
                  </p>
                  {/*<div className="watch-video mt-4 pt-2">*/}
                  {/*  <Link to="#" className="btn btn-primary m-1">Get Started <i className="uil uil-angle-right-b"></i></Link>{" "}*/}
                  {/*  <Link to="#" onClick={this.openModal} className="btn btn-icon btn-pills btn-primary m-1 lightbox">*/}
                  {/*    <FeatherIcon*/}
                  {/*      icon="video"*/}
                  {/*      className="icons"*/}
                  {/*    />*/}
                  {/*  </Link><small className="fw-bold text-uppercase text-white-50 align-middle ms-1">Watch Now</small>*/}
                  {/*  <ModalVideo*/}
                  {/*    channel="youtube"*/}
                  {/*    isOpen={this.state.isOpen}*/}
                  {/*    videoId="yba7hPeTSjk"*/}
                  {/*    onClose={() => this.setState({ isOpen: false })}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
